import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import { SelectBox } from 'devextreme-react/select-box'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import SearchIcon from '@mui/icons-material/Search'
import Typography from '@mui/material/Typography'
import { getSectionLookupList } from 'app/redux/store/features/sections'
import { getTeacherLookupList } from 'app/redux/store/features/teachers'
import { useJumboTheme } from '@jumbo/hooks'
import { Divider, Stack, useMediaQuery } from '@mui/material'
import { Form, Formik } from 'formik'
import { LoadingButton } from '@mui/lab'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'

const ClassGridSidebarFilter = ({ mutation, handleError }) => {
    let initialValues = {}
    const dispatch = useDispatch()
    const [sectionData, setSectionData] = useState('')
    const [teacherData, setTeacherData] = useState('')
    const { classConfig, loading: classLoading, error: classError } = useSelector((state) => state.classes)
    const { sectionLookups, loading: sectionLoading, error: sectionError } = useSelector((state) => state.sections)
    const { teacherLookups, loading: teacherLoading, error: teacherError } = useSelector((state) => state.teachers)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))

    useEffect(() => {
        const error = sectionError || teacherError || classError
        if (error) handleError(error)
    }, [classLoading, sectionLoading, teacherLoading])

    useEffect(() => {
        dispatch(getTeacherLookupList(''))
    }, [])

    // useEffect(() => {
    //     dispatch(getSectionLookupList({ classId: classConfig.classId }))
    // }, [classConfig.classId])

    const sectionLookupsStore = new DataSource({
        store: new ArrayStore({
            key: 'id',
            data: sectionLookups,
            totalCount: sectionLookups.length,
        }),
        reshapeOnPush: true,
    })
    const teacherLookupsStore = new DataSource({
        store: new ArrayStore({
            key: 'id',
            data: teacherLookups,
            totalCount: teacherLookups.length,
        }),
        reshapeOnPush: true,
    })

    const onFormSubmit = async () => {
        if (!classConfig?.classId) {
            return
        }
        await mutation.mutate({
            classId: classConfig.classId, // Add this
            sectionName: sectionData ? sectionData.sectionName : '',
            teacherFirstName: teacherData ? teacherData.firstName : '',
            sort: 'sectionName',
            isDescending: false,
        })
    }

    const onFilterSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true)
        await onFormSubmit()
        setSubmitting(false)
    }

    const handleClear = () => {
        setSectionData(null)
        setTeacherData(null)
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onSubmit={onFilterSubmit}
            onReset={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick noWrapper sx={{ borderRadius: 0 }}>
                        <Divider />
                        <Div
                            className='grid-filter-parent'
                            style={{
                                display: 'flex',
                                margin: '0% 1.5% 4% 1.5%',
                                flexWrap: 'wrap',
                            }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Section Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 210 }}
                                        dataSource={sectionLookupsStore}
                                        displayExpr={'sectionName'}
                                        value={sectionData}
                                        searchEnabled={true}
                                        onValueChange={setSectionData}
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Teacher Name</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 210 }}
                                        dataSource={teacherLookupsStore}
                                        itemTemplate={(data) => data.firstName + ' ' + data.lastName}
                                        displayExpr={(data) => (data ? data.firstName + ' ' + data.lastName : '')}
                                        value={teacherData}
                                        searchEnabled={true}
                                        onValueChange={setTeacherData}
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div className='grid-filter-Button' sx={{ mt: 4.5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <Div>
                                        <LoadingButton
                                            size={'small'}
                                            type='submit'
                                            variant={'contained'}
                                            loading={isSubmitting || mutation.isLoading}>
                                            <SearchIcon />
                                        </LoadingButton>
                                    </Div>
                                    <Div>
                                        <LoadingButton
                                            size={'small'}
                                            type={'reset'}
                                            onClick={handleClear}
                                            variant={'contained'}>
                                            <AutorenewRoundedIcon />
                                        </LoadingButton>
                                    </Div>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default ClassGridSidebarFilter
