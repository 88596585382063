import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    customers: { data: [], totalCount: 0 },
    customerLookups: [],
    loading: false,
    error: null,
    refreshing: false,
}

const customerSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getCustomers: (state, action) => {
            state.customers = action.payload
            state.loading = false
            state.refreshing = false
        },
        getCustomerLookup: (state, action) => {
            state.customerLookups = action.payload
            state.loading = false
        },
        getExistingCustomerLookupById: (state, action) => {
            const index = state.customerLookups.findIndex((customer) => customer.id === action.payload.id)
            if (index >= 0) state.customerLookups[index] = action.payload
            else {
                state.customerLookups.push(action.payload)
            }
            state.loading = false
        },
        getExistingCustomerById: (state, action) => {
            const index = state.customers.data.findIndex((customer) => customer.id === action.payload.id)
            if (index >= 0) {
                state.customers.data[index] = action.payload
            } else {
                state.customers.data.push(action.payload)
                state.customers.totalCount++
            }
            state.loading = false
        },
        addNewCustomer: (state, action) => {
            state.customers.data.push(action.payload)
            state.customers.totalCount++
            state.loading = false
        },
        updateExistingCustomer: (state, action) => {
            const index = state.customers.data.findIndex((customer) => customer.id === action.payload.id)
            if (index >= 0) state.customers[index] = action.payload
            state.loading = false
        },
        removeCustomer: (state, action) => {
            const index = state.customers.data.findIndex((customer) => customer.id === action.payload.id)
            if (index >= 0) {
                state.customers.data.splice(index, 1)
                state.customers.totalCount--
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.id === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    refreshRequested,
    apiRequested,
    apiRequestedFailed,
    getCustomers,
    getCustomerLookup,
    getExistingCustomerLookupById,
    getExistingCustomerById,
    addNewCustomer,
    updateExistingCustomer,
    removeCustomer,
} = customerSlice.actions
export default customerSlice.reducer

export const getCustomerList = (filter, refresh = false) => {
    let url = `/api/Customer/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getCustomers.type,
        onError: apiRequestedFailed.type,
    })
}

export const getCustomerLookupList = (filter) => {
    let url = filter?.firstName ? `/api/Customer/lookup/list?search=${filter.firstName}` : `/api/Customer/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getCustomerLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getCustomerLookupById = (filter) => {
    let url = `/api/Customer/lookup/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingCustomerLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getCustomerById = (filter) => {
    let url = `/api/Customer/${filter.id}?includeBalance=${filter.includeBalance}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingCustomerById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addCustomer = (customer) => {
    let url = `/api/Customer/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: customer,
        onStart: apiRequested.type,
        onSuccess: addNewCustomer.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateCustomer = (customer) => {
    let url = `/api/Customer/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: customer,
        onStart: apiRequested.type,
        onSuccess: updateExistingCustomer.type,
        onError: apiRequestedFailed.type,
    })
}
export const deleteCustomer = (customer) => {
    let url = '/api/Customer/delete'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: customer,
        onStart: apiRequested.type,
        onSuccess: removeCustomer.type,
        onError: apiRequestedFailed.type,
    })
}
