import { createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'
import { getEnrolments } from './enrolments'

const initialState = {
    courseEnrolments: [],
    termSummary: { gender: [] },
    enrolmentSummary: { totalCount: 0, data: [] },
    recentEnrolments: { totalCount: 0, data: [], category: [] },
    overduePayments: { totalCount: 0, data: [] },
    loading: false,
    error: null,
}

const initialState2 = {
    courseEnrolments: [
        {
            id: 'nvMf4SikD5Ir30nvucXT',
            className: 'Quran class Mondays',
            subTitle: '9:00 AM - 12:00 PM',
            capacity: 100,
            registered: 50,
            icon: 'quran',
        },
        {
            id: 'nvMf4SikD5Ir30nvdcXT',
            className: 'Islamic Studies Girls',
            subTitle: '9:00 AM - 12:00 PM',
            capacity: 55,
            registered: 30,
            icon: 'islamic',
        },
        {
            id: 'vvMf4SikD5Ir30nvucXT',
            className: 'Adult Quran Class Friday',
            subTitle: '9:00 AM - 12:00 PM',
            capacity: 10,
            registered: 8,
            icon: 'dua',
        },
        {
            id: 'nvMf4SikD5Ir30nvueXT',
            className: 'Community BBQ',
            subTitle: '9:00 AM - 12:00 PM',
            capacity: 70,
            registered: 55,
            icon: 'event',
        },
    ],
    termSummary: {
        id: 'mSP5CmnVSx85r9kGBr4z',
        termName: 'Summer Term',
        termYear: 2023,
        dateStart: '2024-10-24',
        dateEnd: '2026-10-25',
        totalClasses: 10,
        gender: [
            { genderType: 'Male', count: 35 },
            { genderType: 'Female', count: 60 },
            { genderType: 'Other', count: 5 },
        ],
        totalEnrolments: '12,300',
    },
    enrolmentSummary: {
        days: 7,
        data: [
            {
                enrolmentDate: '27 Nov',
                enrolmentCount: 45,
            },
            {
                enrolmentDate: '28 Nov',
                enrolmentCount: 50,
            },
            {
                enrolmentDate: '29 Nov',
                enrolmentCount: 55,
            },
            {
                enrolmentDate: '1 Dec',
                enrolmentCount: 50,
            },
            {
                enrolmentDate: '2 Dec',
                enrolmentCount: 45,
            },
            {
                enrolmentDate: '3 Dec',
                enrolmentCount: 55,
            },
            {
                enrolmentDate: '4 Dec',
                enrolmentCount: 45,
            },
        ],
    },
    recentEnrolments: {
        totalCount: 5,
        category: [
            { id: 1, className: 'All' },
            { id: 'UXIzuU2PizECkIsxkJAm', className: 'Quran Class' },
            { id: 'XwLRp1H7evrltEUZXO34', className: 'Math Class' },
            { id: 'rmQMmhVfzmvJvjIjOyN7', className: 'Science Class' },
        ],
        data: [
            {
                id: 'UXIzuU2PizECkIsxkJAm',
                firstName: 'Nadeem',
                lastName: 'Janab1',
                phoneNumber: '+921232213232',
                email: 'Ahsan201@yopmail.com',
                familyName: "Faheem's family",
                contactName: "faheem's family Janab",
                termYear: 2023,
                termName: 'Term 1',
                className: 'Quran Class',
                createTime: '2023-10-04T11:22:43.779778+00:00',
                amount: 129,
                payment: 100,
            },
            {
                id: 'UXIzuU2PizECkIsxkJAm',
                firstName: 'Nadeem',
                lastName: 'Janab1',
                phoneNumber: '+921232213232',
                email: 'Ahsan201@yopmail.com',
                familyName: "Usman's family",
                contactName: "Usman's family Janab",
                termYear: 2023,
                termName: 'Term 1',
                className: 'Quran Class',
                createTime: '2023-10-04T11:22:43.779778+00:00',
                amount: 170,
                payment: 120,
            },
            {
                id: 'XwLRp1H7evrltEUZXO34',
                firstName: 'Nadeem',
                lastName: 'Janab1',
                phoneNumber: '+921232213232',
                email: 'Ahsan201@yopmail.com',
                familyName: "Abdullah's family",
                contactName: "Abdullah's family Janab",
                termYear: 2023,
                termName: 'Term 1',
                className: 'Math Class',
                createTime: '2023-10-04T11:22:43.779778+00:00',
                amount: 129,
                payment: 100,
            },
            {
                id: 'XwLRp1H7evrltEUZXO34',
                firstName: 'Ahsan1',
                lastName: 'Janab1',
                phoneNumber: '+921232213632',
                email: 'Ahsan541@yopmail.com',
                familyName: "Abdullah's family",
                contactName: "Abdullah's family Janab",
                termYear: 2023,
                termName: 'Term 1',
                className: 'Math Class',
                createTime: '2023-10-06T05:57:28.86473+00:00',
                amount: 29,
                payment: 29,
            },
            {
                id: 'rmQMmhVfzmvJvjIjOyN7',
                firstName: 'Nadeem',
                lastName: 'Malik',
                phoneNumber: '+921232213632',
                email: 'Naddeem541@yopmail.com',
                familyName: "Abdullah's family",
                contactName: "Abdullah's family Janab",
                termYear: 2023,
                termName: 'Term 1',
                className: 'Science Class',
                createTime: '2023-10-04T13:58:23.862545+00:00',
                amount: 55,
                payment: 55,
            },
        ],
    },
    overduePayments: {
        totalCount: 5,
        data: [
            {
                id: 'rmQMmhVfzmvJvjIjOyP71',
                className: 'Quran Class',
                contactName: 'Aamir Sohail',
                phoneNumber: '+921232213632',
                amount: 291,
                payment: 100,
                overdue: 191,
                overdueDate: '2023-10-04T13:58:23.862545+00:00',
            },
            {
                id: 'rmQMmhVfzmvJvjIjOyP72',
                className: 'Islamic Studies Class',
                contactName: 'Abdullah',
                phoneNumber: '+921232213632',
                amount: 291,
                payment: 100,
                overdue: 191,
                overdueDate: '2023-10-04T13:58:23.862545+00:00',
            },
            {
                id: 'rmQMmhVfzmvJvjIjOyP73',
                className: 'Hadith Class',
                contactName: 'Adnan Sadiq',
                phoneNumber: '+921232213632',
                amount: 291,
                payment: 100,
                overdue: 191,
                overdueDate: '2023-10-04T13:58:23.862545+00:00',
            },
            {
                id: 'rmQMmhVfzmvJvjIjOyP74',
                className: 'Quran Class',
                contactName: 'Rafiq Ahmed',
                phoneNumber: '+921232213632',
                amount: 291,
                payment: 100,
                overdue: 191,
                overdueDate: '2023-10-04T13:58:23.862545+00:00',
            },
            {
                id: 'rmQMmhVfzmvJvjIjOyP75',
                className: 'Islamic Studies Class',
                contactName: 'Waqas gul',
                phoneNumber: '+921232213632',
                amount: 291,
                payment: 100,
                overdue: 191,
                overdueDate: '2023-10-04T13:58:23.862545+00:00',
            },
            {
                id: 'rmQMmhVfzmvJvjIjOyP76',
                className: 'Quran Class',
                contactName: 'Haji Ramzan',
                phoneNumber: '+921232213632',
                amount: 291,
                payment: 100,
                overdue: 191,
                overdueDate: '2023-10-04T13:58:23.862545+00:00',
            },
        ],
    },
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
        },
        getTermDashboardData: (state, action) => {
            state.courseEnrolments = action.payload.courseEnrolments
            state.termSummary = action.payload.termSummary
            state.enrolmentSummary = action.payload.enrolmentSummary
            state.recentEnrolments = action.payload.recentEnrolments
            state.overduePayments = action.payload.overduePayments
            state.loading = false
        },
    },
})

export const { apiRequested, apiRequestedFailed, getTermDashboardData } = dashboardSlice.actions

export const getTermDashboard = (filter) => {
    let url = `/api/Dashboard/term`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getTermDashboardData.type,
        onError: apiRequestedFailed.type,
    })
}
export default dashboardSlice.reducer
