import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import AddIcon from '@mui/icons-material/Add'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import { getUserList } from 'app/redux/store/features/users'
import UserGridFilter from './components/UserGridFilter'
import UserGridData from './components/UserGridData'
import utilServices from '../../../services/util-services'

const UserGrid = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)
    const { users } = useSelector((state) => state.users)
    const { state } = useLocation()

    const [filter, setFilter] = useState(() =>
        state?.navigateFrom === 'cancel-add-user' ||
        state?.navigateFrom === 'cancel-update-user' ||
        state?.navigateFrom === 'add-user' ||
        state?.navigateFrom === 'update-user'
            ? { firstName: '', lastName: '', email: '', sort: 'firstName', isDescending: false }
            : { firstName: '', lastName: '', email: '', sort: 'firstName', isDescending: false }
    )

    useEffect(() => {
        if (utilServices.isNullOrUndefined(state?.navigateFrom) || !users || !users.data || users.data.length <= 0) {
            setApiCallInProgress(true)
            dispatch(getUserList({ ...filter, skip: 0, take: 25 }))
        }
    }, [])

    function init(initialValue) {
        return {
            gridFilterVisible: initialValue.gridFilterVisible,
            filterIconColor: initialValue.filterIconColor,
        }
    }

    function userReducer(state, action) {
        switch (action.type) {
            case 'set-grid-filter':
                return {
                    ...state,
                    gridFilterVisible: action.payload,
                }
            case 'set-filter-icon-color':
                return {
                    ...state,
                    filterIconColor: action.payload,
                }
            default:
                return state
        }
    }

    const [userGrid, setuserGrid] = React.useReducer(
        userReducer,
        {
            gridFilterVisible: false,
            filterIconColor: false,
        },
        init
    )

    const applyFilter = useMutation({
        mutationFn: (currentfilter) => {
            dispatch(getUserList({ ...currentfilter, skip: 0, take: 25 }, true))
            setFilter(currentfilter)
        },
    })

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, skip: 25 * pageNumber, take: 25 }
        dispatch(getUserList(currentFilter))
        setFilter(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const currentFilter = {
            ...filter,
            sort: sortOrder[0].columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: 25,
        }
        dispatch(getUserList(currentFilter))
        setFilter(currentFilter)
    }

    const onRefresh = () => {
        setApiCallInProgress(true)
        dispatch(getUserList({ ...filter, skip: 0, take: 25 }, true))
        setCurrentPage(0)
    }

    const setGridFilter = React.useCallback(
        (value) => {
            setuserGrid({ type: 'set-grid-filter', payload: value })
        },
        [setuserGrid]
    )

    const onAddUser = () => {
        navigate('/security/users/add')
    }

    const setFilterIconColor = React.useCallback(
        (value) => {
            setuserGrid({ type: 'set-filter-icon-color', payload: value })
        },
        [setuserGrid]
    )

    return (
        <JumboCardQuick
            noWrapper
            title={
                <Typography color={'common.white'} variant={'h3'}>
                    <AutorenewRoundedIcon
                        onClick={onRefresh}
                        fontSize={'large'}
                        sx={{
                            mb: -1.2,
                            mr: 0.5,
                            ml: -2,
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                    User List
                </Typography>
            }
            headerSx={{
                height: 40,
                bgcolor: 'primary.main',
            }}
            action={
                <Stack direction={'row'} spacing={4} sx={{ mt: -2 }}>
                    <AddIcon
                        fontSize={'large'}
                        variant={'contained'}
                        sx={{
                            pt: 0,
                            pb: 0,
                            color: 'common.white',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onClick={onAddUser}
                    />
                    <FilterAltIcon
                        fontSize='large'
                        sx={{
                            mt: -1.8,
                            color:
                                userGrid.filterIconColor && !userGrid.gridFilterVisible
                                    ? 'success.main'
                                    : 'common.white',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                        onClick={() => setGridFilter(!userGrid.gridFilterVisible)}
                    />
                </Stack>
            }>
            <Grid>
                <UserGridFilter
                    mutation={applyFilter}
                    display={userGrid.gridFilterVisible ? '' : 'none'}
                    filterIconColor={(data) => setFilterIconColor(data)}
                />
            </Grid>
            <Grid>
                <UserGridData
                    onRefresh={onRefresh}
                    onPageChange={onPageChange}
                    onColumnSort={onColumnSort}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    apiCallInProgress={apiCallInProgress}
                    setApiCallInProgress={setApiCallInProgress}
                />
            </Grid>
        </JumboCardQuick>
    )
}
export default UserGrid
