import { Table } from '@devexpress/dx-react-grid-material-ui'
import { CircularProgress } from '@mui/material'
import React from 'react'

const NoDataCellWithLoading = ({ ...props }) => {
    const noDataImgUrl = '/images/no_data.png'


    return props.loading ? (
        <Table.Cell rowSpan={5} {...props} style={{ textAlign: 'center', padding: '50px 0' }}>
            <CircularProgress></CircularProgress>
        </Table.Cell>
    ) : (
        <Table.NoDataCell {...props} getMessage={() => 'No data'}></Table.NoDataCell>
    )
}
export default NoDataCellWithLoading
