import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CustomPaging, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useSnackbar } from 'notistack'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Link from '@mui/material/Link'
import { Paper, useMediaQuery } from '@mui/material'
import { getEnrolmentById, deleteEnrolment } from 'app/redux/store/features/enrolments'
import NoDataCellWithLoading from 'app/services/utils/components/NoDataCellWithLoading'
import useJumboTheme from '../../../../../@jumbo/hooks/useJumboTheme'
import LoadingCircle from 'app/services/utils/components/LoadingCircle/LoadingCircle'

const EnrolmentGridData = ({
    onRefresh,
    onPageChange,
    onColumnSort,
    setCurrentPage,
    currentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { showDialog, hideDialog } = useJumboDialog()
    const { enqueueSnackbar } = useSnackbar()
    const [sorting, setSorting] = useState([{ columnName: 'firstName', direction: 'desc' }])
    const pageSize = 25
    const { enrolments, loading, error, refreshing } = useSelector((state) => state.enrolments)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = (id) => {
        dispatch(getEnrolmentById({ id: id }))
        navigate(`/registrations/enrolments/edit/${id}`, { state: { enrolmentId: id } })
    }

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    onNavigatingToDetail(tableRow.row.id)
                }}
            />
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton
                    onClick={() => {
                        onNavigatingToDetail(row.id)
                    }}>
                    <EditIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
                <IconButton onClick={() => onRemoveUser(row.id)}>
                    <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const hideDialogAndRefreshUserList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Enrolment data removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            onRefresh() // Calling the refresh function from the parent component
        }
    }

    const onRemoveUser = async (userId) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(deleteEnrolment({ id: userId }))
                hideDialogAndRefreshUserList()
            },
            onNo: hideDialog,
        })
    }

    const termCellRender = (data) => {
        return data.termYear + ' / ' + data.termNumber
    }

    const fullMemberNameRenderLinkCell = (row) => {
        if (!row.memberFirstName || !row.memberLastName) {
            return ''
        }

        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => onNavigatingToDetail(row.id)}
                sx={{
                    '&:hover': {
                        color: 'action.active', // Change this to the color you want on hover
                        cursor: 'pointer',
                    },
                }}>
                {`${row.memberFirstName} ${row.memberLastName}`}
            </Link>
        )
    }

    const columns = [
        { name: 'memberFirstName', title: 'Member Name', getCellValue: fullMemberNameRenderLinkCell },
        { name: 'familyName', title: 'Family Name' },
        { name: 'firstName', title: 'First Name' },
        { name: 'lastName', title: 'Last Name' },
        { name: 'phoneNumber', title: 'Phone Number' },
        { name: 'termYear', title: 'Term', getCellValue: termCellRender },
        { name: 'className', title: 'Class Name' },
        { name: 'sectionName', title: 'Section Name' },
        { name: 'email', title: 'Email' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'memberFirstName', width: min ? '110px' : '12%', minWidth: '110px', wordWrapEnabled: true },
            { columnName: 'familyName', width: min ? '110px' : '10%', minWidth: '110px', wordWrapEnabled: true },
            { columnName: 'firstName', width: min ? '110px' : '9%', minWidth: '110px', wordWrapEnabled: true },
            { columnName: 'lastName', width: min ? '110px' : '9%', minWidth: '110px', wordWrapEnabled: true },
            { columnName: 'familyName', width: min ? '110px' : '10%', minWidth: '110px', wordWrapEnabled: true },
            { columnName: 'phoneNumber', width: min ? '120px' : '9%', minWidth: '120px', wordWrapEnabled: true },
            {
                columnName: 'termYear',
                width: min ? '80px' : '8%',
                minWidth: '80px',
                wordWrapEnabled: true,
                align: 'center',
            },
            { columnName: 'className', width: min ? '120px' : '11%', minWidth: '120px', wordWrapEnabled: true },
            { columnName: 'sectionName', width: min ? '120px' : '10%', minWidth: '120px', wordWrapEnabled: true },
            { columnName: 'email', width: min ? '200px' : '15%', minWidth: '200px', wordWrapEnabled: true },
            {
                columnName: 'Action',
                width: min ? '90px' : '7%',
                minWidth: '90px',
                wordWrapEnabled: true,
                align: 'center',
            },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={enrolments.data} columns={columns}>
                {enrolments.totalCount !== 0 && <LoadingCircle refreshing={refreshing} />}
                <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnSortingEnabled={true}
                    columnExtensions={sortingColumnExtensions}
                />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={enrolments.totalCount} />
                <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                    defaultColumnWidths={columnWidths}
                    resizingMode='nextColumn'
                    columnExtensions={columnWidths}
                />
                <TableColumnVisibility />
                <TableHeaderRow showSortingControls />
                <SelectionState />
                <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default EnrolmentGridData
