import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useSnackbar } from 'notistack'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Link from '@mui/material/Link'
import { Paper, useMediaQuery } from '@mui/material'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { deleteMember } from 'app/redux/store/features/members'
import GenderTypeService from 'app/pages/registrations/customers/components/GenderTypeService'
import { formatDateTime } from 'app/services/utils/components/FormatDateTime/FormatDateTime'
import { formattedDate } from 'app/services/utils/components/FormatDateTime/FormatDateTime'
import NoDataCellWithLoading from 'app/services/utils/components/NoDataCellWithLoading'
import LoadingCircle from 'app/services/utils/components/LoadingCircle/LoadingCircle'
import { useJumboTheme } from '@jumbo/hooks'

const MemberGridData = ({
    onRefresh,
    onPageChange,
    onColumnSort,
    setCurrentPage,
    currentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { showDialog, hideDialog } = useJumboDialog()
    const { enqueueSnackbar } = useSnackbar()
    const [memberId, setMemberId] = useState(null)
    const [memberIdToRemove, setMemberIdToRemove] = useState(null)
    const dateTimeColumns = ['updateTime']
    const dateColumns = ['birthDate']
    const [sorting, setSorting] = useState([{ columnName: 'firstName', direction: 'asc' }])
    const pageSize = 25
    const { members, loading: memberLoading, error: memberError, refreshing } = useSelector((state) => state.members)
    const { familyConfig, loading: familyLoading, error: familyError } = useSelector((state) => state.families)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || memberLoading || familyLoading) return
        setApiCallInProgress(false)
        const error = memberError || familyError
        if (error) handleError(error)
    }, [memberLoading || familyLoading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    useEffect(() => {
        if (memberId) {
            navigate(`/registrations/families/${familyConfig.familyId}/member/edit/${memberId}`, {
                state: { familyId: familyConfig.familyId, memberId: memberId },
            })
        }
        if (memberIdToRemove) {
            dispatch(deleteMember({ familyId: familyConfig.familyId, id: memberIdToRemove }))
            hideDialogAndRefreshUserList()
        }
    }, [memberId, memberIdToRemove, navigate])

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    setMemberId(tableRow.row.id)
                }}
            />
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={memberLoading} {...props} />

    const DateTimeTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const DateTypeProvider = (props) => <DataTypeProvider formatterComponent={formattedDate} {...props} />

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton onClick={() => setMemberId(row.id)}>
                    <EditIcon title='Edit row' sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
                <IconButton onClick={() => onRemoveUser(row.id)}>
                    <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => setMemberId(row.id)}
                sx={{
                    '&:hover': {
                        color: 'action.active',
                        cursor: 'pointer',
                    },
                }}>
                {row.firstName}
            </Link>
        )
    }

    const hideDialogAndRefreshUserList = () => {
        hideDialog()
        if (memberError) {
            enqueueSnackbar(memberError.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Member data removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            onRefresh() // Calling the refresh function from the parent component
        }
    }

    const onRemoveUser = async (id) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                setMemberIdToRemove(id)
            },
            onNo: hideDialog,
        })
    }

    const columns = [
        { name: 'firstName', title: 'First Name', getCellValue: renderLinkCell },
        { name: 'lastName', title: 'Last Name' },
        { name: 'phoneNumber', title: 'Phone No' },
        { name: 'email', title: 'Email' },
        { name: 'genderType', title: 'Gender', getCellValue: GenderTypeService.getGenderTypeData },
        { name: 'birthDate', title: 'Birth Date' },
        { name: 'updateTime', title: 'Updated Date' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'firstName', width: min ? '120px' : '13%', wordWrapEnabled: true, minWidth: '110px' },
            { columnName: 'lastName', width: min ? '120px' : '12%', wordWrapEnabled: true, minWidth: '110px' },
            { columnName: 'phoneNumber', width: min ? '130px' : '12%', minWidth: '130px' },
            { columnName: 'email', width: min ? '190px' : '18%', wordWrapEnabled: true, minWidth: '190px' },
            { columnName: 'genderType', width: min ? '100px' : '9%', wordWrapEnabled: true, minWidth: '100px' },
            { columnName: 'birthDate', width: min ? '110px' : '11%', wordWrapEnabled: true, minWidth: '110px' },
            { columnName: 'updateTime', width: min ? '120px' : '17%', wordWrapEnabled: true, minWidth: '120px' },
            {
                columnName: 'Action',
                width: min ? '90px' : '8%',
                wordWrapEnabled: true,
                minWidth: '90px',
                align: 'center',
            },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [{ columnName: 'Action', sortingEnabled: false }]

    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={members.data} columns={columns}>
                {members.totalCount !== 0 && <LoadingCircle refreshing={refreshing} />}
                <DateTimeTypeProvider for={dateTimeColumns} />
                <DateTypeProvider for={dateColumns} />
                <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnSortingEnabled={true}
                    columnExtensions={sortingColumnExtensions}
                />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={members.totalCount} />
                <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                    defaultColumnWidths={columnWidths}
                    resizingMode='nextColumn'
                    columnExtensions={columnWidths}
                />
                <TableColumnVisibility />
                <TableHeaderRow showSortingControls />
                <SelectionState />
                <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default MemberGridData
