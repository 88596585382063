import React, { useEffect, useState, useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { CustomPaging, DataTypeProvider, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper, useMediaQuery } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useDispatch, useSelector } from 'react-redux'
import { formatDateTime } from '../../../../services/utils/components/FormatDateTime/FormatDateTime'
import formatCurrency from 'app/utils/components/formatCurrency/formatCurrency'
import Link from '@mui/material/Link'
import { getTransactionById } from 'app/redux/store/features/transactions'
import NoDataCellWithLoading from 'app/services/utils/components/NoDataCellWithLoading'
import useJumboTheme from '../../../../../@jumbo/hooks/useJumboTheme'
import LoadingCircle from 'app/services/utils/components/LoadingCircle/LoadingCircle'

const TransactionGridData = ({
    onPageChange,
    onColumnSort,
    currentPage,
    setCurrentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { transactions, loading, error, refreshing } = useSelector((state) => state.transactions)
    const dateTimeColumns = ['dateTxn', 'endDate']
    const [sorting, setSorting] = useState([{ columnName: 'dateTxn', direction: 'desc' }])
    const pageSize = 25
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = (id) => {
        dispatch(getTransactionById({ id: id, onlineTxn: false }))
        navigate(`/registrations/transactions/details/${id}`, { state: { id: id } })
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />

    const DateTimeTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const renderLinkCell = (row) => {
        return (
            <Link
                onClick={() => onNavigatingToDetail(row.id)}
                variant='text'
                underline='none'
                sx={{
                    '&:hover': {
                        color: 'action.active',
                        cursor: 'pointer',
                    },
                }}>
                {row.txnNumber}
            </Link>
        )
    }

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <TableSelection.Row
                {...restProps}
                onDoubleClick={() => {
                    onNavigatingToDetail(tableRow.row.id)
                }}
            />
        )
    }

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton onClick={() => onNavigatingToDetail(row.id)}>
                    <VisibilityIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const customercellRender = (data) => {
        return data.termYear + ' / ' + data.termNumber
    }

    const fullNamecellRender = (data) => {
        return data.firstName + ' ' + data.lastName
    }

    const columns = [
        { name: 'txnNumber', title: 'Txn No', getCellValue: renderLinkCell },
        { name: 'dateTxn', title: 'Date' },
        { name: 'txnStatusName', title: 'Status' },
        { name: 'firstName', title: 'Customer', getCellValue: fullNamecellRender },
        { name: 'termYear', title: 'Term', getCellValue: customercellRender },
        { name: 'className', title: 'Class' },
        { name: 'amountPaid', title: 'Amount', getCellValue: (row) => formatCurrency(row.amountPaid) },
        { name: 'balance', title: 'Balance', getCellValue: (row) => formatCurrency(row.balance) },
        { name: 'comments', title: 'Comments' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'txnNumber', width: min ? '110px' : '9%', minWidth: '110px', wordWrapEnabled: true },
            { columnName: 'dateTxn', width: min ? '110px' : '13%', minWidth: '110px', wordWrapEnabled: true },
            { columnName: 'txnStatusName', width: min ? '100px' : '9%', minWidth: '100px', wordWrapEnabled: true },
            { columnName: 'firstName', width: min ? '130px' : '11%', minWidth: '130px', wordWrapEnabled: true },
            { columnName: 'termYear', width: min ? '90px' : '9%', minWidth: '90px', align: 'center' },
            { columnName: 'className', width: min ? '130px' : '11%', minWidth: '130px', wordWrapEnabled: true },
            { columnName: 'amountPaid', width: min ? '100px' : '8%', minWidth: '100px', align: 'right' },
            { columnName: 'balance', width: min ? '100px' : '8%', minWidth: '100px', align: 'right' },
            { columnName: 'comments', width: min ? '150px' : '15%', minWidth: '150px', wordWrapEnabled: true },
            { columnName: 'Action', width: min ? '90px' : '7%', minWidth: '90px', align: 'center' },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [
        { columnName: 'Action', sortingEnabled: false },
        { columnName: 'txnStatusName', sortingEnabled: false },
        { columnName: 'termYear', sortingEnabled: false },
    ]

    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={transactions?.data} columns={columns}>
                {transactions.totalCount !== 0 && <LoadingCircle refreshing={refreshing} />}
                <DateTimeTypeProvider for={dateTimeColumns} />
                <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnSortingEnabled={true}
                    columnExtensions={sortingColumnExtensions}
                />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={transactions.totalCount} />
                <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                    defaultColumnWidths={columnWidths}
                    resizingMode='nextColumn'
                    columnExtensions={columnWidths}
                />
                <TableColumnVisibility />
                <TableHeaderRow showSortingControls />
                <SelectionState />
                <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default TransactionGridData
