import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CustomPaging, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import {
    Grid,
    Table,
    TableHeaderRow,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { useSnackbar } from 'notistack'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import Link from '@mui/material/Link'
import { Paper, useMediaQuery } from '@mui/material'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import { getFamilyById, deleteFamily, getFamilyConfig, updateFamilyConfig } from 'app/redux/store/features/families'
import NoDataCellWithLoading from 'app/services/utils/components/NoDataCellWithLoading'
import LoadingCircle from 'app/services/utils/components/LoadingCircle/LoadingCircle'
import { useJumboTheme } from '@jumbo/hooks'
import { styled } from '@mui/material/styles'

const FamilyGridData = ({
    onRefresh,
    onPageChange,
    onColumnSort,
    setCurrentPage,
    currentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { showDialog, hideDialog } = useJumboDialog()
    const { enqueueSnackbar } = useSnackbar()
    const [selectedRowIds, setSelectedRowIds] = useState([])
    const [sorting, setSorting] = useState([{ columnName: 'familyName', direction: 'asc' }])
    const pageSize = 25
    const { families, familyConfig, loading, error, refreshing } = useSelector((state) => state.families)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)

        if (families.data.length > 0 && !familyConfig.familyId) {
            dispatch(getFamilyConfig({ familyId: families.data[0].id }))
            setSelectedRowIds([families.data[0]?.id])
        }
    }, [loading, familyConfig])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }
    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const onNavigatingToDetail = (id) => {
        dispatch(getFamilyById({ id: id }))
        navigate(`/registrations/families/edit/${id}`, { state: { familieId: id } })
    }

    const StyledTableRow = styled(TableSelection.Row)(({ theme, selected }) => ({
        ...(selected && {
            backgroundColor: `${theme.palette.action.selected} !important`,
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            },
        }),
    }))

    const TableRow = ({ tableRow, ...restProps }) => (
        <StyledTableRow
            {...restProps}
            onDoubleClick={() => onNavigatingToDetail(tableRow.row.id)}
            onClick={() => {
                dispatch(updateFamilyConfig({ familyId: tableRow.row.id }))
                setSelectedRowIds([tableRow.row.id])
            }}
            selected={selectedRowIds.includes(tableRow.row.id)}
        />
    )

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />

    const onSelectionChange = (selectedRowIds) => {
        setSelectedRowIds(selectedRowIds)
    }

    const renderActionCell = (row) => {
        return (
            <div>
                <IconButton onClick={() => onNavigatingToDetail(row.id)}>
                    <EditIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
                <IconButton onClick={() => onRemoveUser(row.id)}>
                    <DeleteIcon sx={{ color: 'primary.main', fontSize: '18px' }} />
                </IconButton>
            </div>
        )
    }

    const renderLinkCell = (row) => {
        return (
            <Link
                variant='text'
                underline='none'
                onClick={() => onNavigatingToDetail(row.id)}
                sx={{
                    '&:hover': {
                        color: 'action.active',
                        cursor: 'pointer',
                    },
                }}>
                {row.familyName}
            </Link>
        )
    }

    const hideDialogAndRefreshUserList = () => {
        hideDialog()
        if (error) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar('Family data removed successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            onRefresh() // Calling the refresh function from the parent component
        }
    }

    const onRemoveUser = async (id) => {
        showDialog({
            variant: 'confirm',
            title: 'This will remove the selected record, do you want to continue?',
            onYes: () => {
                dispatch(deleteFamily({ id: id }))
                hideDialogAndRefreshUserList()
            },
            onNo: hideDialog,
        })
    }

    const columns = [
        { name: 'familyName', title: 'Family Name', getCellValue: renderLinkCell },
        { name: 'firstName', title: 'First Name' },
        { name: 'Action', title: 'Action', getCellValue: renderActionCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'familyName', width: min ? '150px' : '38%', minWidth: '150px', wordWrapEnabled: true },
            { columnName: 'firstName', width: min ? '150px' : '37%', minWidth: '120px', wordWrapEnabled: true },
            { columnName: 'Action', width: min ? '87px' : '25%', minWidth: '87px', align: 'center' },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [
        { columnName: 'reportXClientId', sortingEnabled: false },
        { columnName: 'Action', sortingEnabled: false },
    ]

    return (
        <Paper style={{ position: 'relative' }}>
            {
                <Grid rows={families.data} columns={columns} rowSelection={'single'}>
                    {families.totalCount !== 0 && <LoadingCircle refreshing={refreshing} />}
                    <SortingState
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                        columnSortingEnabled={true}
                        columnExtensions={sortingColumnExtensions}
                    />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={families.totalCount} />
                    <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                        defaultColumnWidths={columnWidths}
                        resizingMode='nextColumn'
                        columnExtensions={columnWidths}
                    />
                    <TableColumnVisibility />
                    <TableHeaderRow showSortingControls />
                    <SelectionState selectedRowIds={selectedRowIds} onSelectionChange={onSelectionChange} />
                    <TableSelection
                        highlightRow={true}
                        selectByRowClick={true}
                        showSelectAll={false}
                        rowComponent={TableRow}
                        showSelectionColumn={false}
                        rowSelection={'single'}
                    />
                    <PagingPanel />
                </Grid>
            }
        </Paper>
    )
}

export default FamilyGridData
