import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useDispatch } from 'react-redux'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import PaymentsGridFilter from './components/PaymentsGridFilter'
import PaymentsGridData from './components/PaymentsGridData'
import { getPaymentList } from 'app/redux/store/features/payments'
import DateUtils from 'app/utils/components/DateUtils/DateUtils'

const PaymentsGrid = () => {
    const pageSize = 25

    const [filter, setFilter] = useState({
        startDate: DateUtils.getStartDate(10),
        endDate: DateUtils.getEndDate(),
        customerId: '',
        sort: 'datePayment',
        isDescending: true,
    })

    const dispatch = useDispatch()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const [currentPage, setCurrentPage] = useState(0)

    useEffect(() => {
        setApiCallInProgress(true)
        dispatch(getPaymentList({ ...filter, skip: 0, take: pageSize }))
    }, [dispatch])

    function init(initialValue) {
        return {
            gridFilterVisible: initialValue.gridFilterVisible,
            filterIconColor: initialValue.filterIconColor,
        }
    }

    function paymentReducer(state, action) {
        switch (action.type) {
            case 'set-grid-filter':
                return {
                    ...state,
                    gridFilterVisible: action.payload,
                }
            case 'set-filter-icon-color':
                return {
                    ...state,
                    filterIconColor: action.payload,
                }
            default:
                return state
        }
    }

    const [paymentGrid, setPaymentGrid] = React.useReducer(
        paymentReducer,
        {
            gridFilterVisible: false,
            filterIconColor: false,
        },
        init
    )

    const applyFilter = useMutation({
        mutationFn: (currentfilter) => {
            dispatch(getPaymentList({ ...currentfilter, skip: 0, take: pageSize }, true))
            setFilter(currentfilter)
        },
    })

    const onPageChange = (pageNumber) => {
        const currentFilter = { ...filter, skip: pageSize * pageNumber, take: pageSize }
        dispatch(getPaymentList(currentFilter))
        setFilter(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const currentFilter = {
            ...filter,
            sort: sortOrder[0].columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        dispatch(getPaymentList(currentFilter))
        setFilter(currentFilter)
    }

    const onRefresh = () => {
        setApiCallInProgress(true)
        dispatch(getPaymentList({ ...filter, skip: 0, take: pageSize }, true))
        setCurrentPage(0)
    }

    const setGridFilter = React.useCallback(
        (value) => {
            setPaymentGrid({ type: 'set-grid-filter', payload: value })
        },
        [setPaymentGrid]
    )

    const setFilterIconColor = React.useCallback(
        (value) => {
            setPaymentGrid({ type: 'set-filter-icon-color', payload: value })
        },
        [setPaymentGrid]
    )

    return (
        <React.Fragment>
            <Grid sx={{ width: '99%' }}>
                <JumboCardQuick
                    noWrapper
                    title={
                        <Typography color={'common.white'} variant={'h3'}>
                            <AutorenewRoundedIcon
                                onClick={onRefresh}
                                fontSize={'large'}
                                sx={{
                                    mb: -1.2,
                                    mr: 0.5,
                                    ml: -2,
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                            />
                            Payments History
                        </Typography>
                    }
                    headerSx={{
                        height: 40,
                        bgcolor: 'primary.main',
                    }}
                    action={
                        <Stack direction={'row'} spacing={4} sx={{ mt: -2 }}>
                            <FilterAltIcon
                                fontSize='large'
                                sx={{
                                    mt: 0,
                                    color:
                                        paymentGrid.filterIconColor && !paymentGrid.gridFilterVisible
                                            ? 'success.main'
                                            : 'common.white',
                                    '&:hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                                onClick={() => setGridFilter(!paymentGrid.gridFilterVisible)}
                            />
                        </Stack>
                    }>
                    <Grid>
                        <PaymentsGridFilter
                            mutation={applyFilter}
                            display={paymentGrid.gridFilterVisible ? '' : 'none'}
                            filterIconColor={(data) => setFilterIconColor(data)}
                        />
                    </Grid>
                    <Grid>
                        <PaymentsGridData
                            onRefresh={onRefresh}
                            onPageChange={onPageChange}
                            onColumnSort={onColumnSort}
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            apiCallInProgress={apiCallInProgress}
                            setApiCallInProgress={setApiCallInProgress}
                        />
                    </Grid>
                </JumboCardQuick>
            </Grid>
        </React.Fragment>
    )
}

export default PaymentsGrid
