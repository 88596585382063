import React from 'react'
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts'
import { useSelector } from 'react-redux'
import Div from '@jumbo/shared/Div'
import { Chip, Typography } from '@mui/material'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { capitalizeFLetter } from '@jumbo/utils'
import { Grid } from '@mui/material'
import { locale } from 'devextreme/localization'
import LoaderOverlayDashboard from 'app/services/utils/components/LoaderOverlayDashboard'

const BannerRight = () => {
    const { enrolmentSummary, loading } = useSelector((state) => state.dashboard)

    const formattedDate = (input) => {
        const value = input && typeof input === 'object' ? input.value : input
        if (!value) return ''
        const date = new Date(value)
        const formattedDate = new Intl.DateTimeFormat(locale.languageTag, {
            month: 'numeric',
            day: 'numeric',
        }).format(date)
        return formattedDate
    }
    return (
        <Grid>
            <JumboCardQuick
                title={
                  <>
                    <Div sx={{ position: 'relative' }}>
                    <LoaderOverlayDashboard loading={loading} />
                </Div>

<Div >
                    <Typography fontFamily='Lobster, cursive' variant={'h3'} mb={0} color={'info.dark'}>
                        Daily Enrolments
                    </Typography>
                        </Div>
                        </>
                }
                action={<Chip label={'Last ' + enrolmentSummary.days + ' days'} color={'info'} size={'small'} />}
                wrapperSx={{ pt: 0 }}>
                <ResponsiveContainer height={200} debounce={200}>
                    <BarChart data={enrolmentSummary.data}>
                        <Tooltip
                            animationEasing={'ease-in-out'}
                            content={({ active, label, payload }) => {
                                return active && payload !== null ? (
                                    <Div sx={{ color: 'common.white' }}>
                                        {payload.map((row, index) => {
                                            return (
                                                <div key={index} className={index !== payload.length - 1 ? 'mb-1' : ''}>
                                                    <div
                                                        style={{
                                                            color: row.color,
                                                            fontSize: 8,
                                                            letterSpacing: 2,
                                                            textTransform: 'uppercase',
                                                        }}>
                                                        {capitalizeFLetter('Enrolments ')}
                                                    </div>
                                                    <div
                                                        style={{
                                                            color: row.color,
                                                        }}>
                                                        {row.value}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Div>
                                ) : null
                            }}
                            wrapperStyle={{
                                background: 'rgba(0,0,0,0.8)',
                                borderRadius: 4,
                                padding: '5px 8px',
                                fontWeight: 500,
                                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                            }}
                            cursor={false}
                        />
                        <XAxis
                            dataKey='enrolmentDate'
                            tickLine={false}
                            axisLine={false}
                            tickFormatter={formattedDate}
                        />
                        <Bar dataKey='enrolmentCount' fill='#3EC3D0' stackId={'a'} maxBarSize={10} barSize={4} />
                        <Bar dataKey='past' fill='#CDD8E4' stackId={'a'} maxBarSize={10} barSize={4} />
                    </BarChart>
                </ResponsiveContainer>
            </JumboCardQuick>
        </Grid>
    )
}

export default BannerRight
