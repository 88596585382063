import React from 'react'
import { useNavigate } from 'react-router-dom'
import Form, { ButtonItem, SimpleItem, EmptyItem, GroupItem, ColCountByScreen, Label } from 'devextreme-react/form'
import 'devextreme-react/tag-box'
import { useSelector } from 'react-redux'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { PatternRule } from 'devextreme-react/data-grid'

const AddBannerBarForm = (props) => {
    const navigate = useNavigate()
    const { loading } = useSelector((state) => state.banners)

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/settings/configuration/banner-bar'),
    }

    const validationRules = {
        dateStart: [{ type: 'required', message: 'Date Start is required.' }],
        dateEnd: [{ type: 'required', message: 'Date End is required.' }],
        sortOrder: [{ type: 'required', message: 'Sort order is required.' }],
        file: [
            { type: 'required', message: 'Image is required.' },
            {
                type: 'custom',
                validationCallback: (e) => e.value[0]?.size <= 256 * 1024,
                message: 'Image should not be more than 256 KB.',
            },
        ],
    }

    const navigationLinkEditorOptions = {
        valueChangeEvent: 'keyup',
        maskRules: {
            X: /\d/,
        },
    }

    const navigationLinkPattern = /^(?=.{16,})https:\/\/.*$/

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={`h3`} sx={{ mt: 0.8 }}>
                        Add Banner Bar
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <form action='' onSubmit={props.addBannerBar}>
                    <Form
                        mode='form'
                        formData={props.BannerBarData}
                        showColonAfterLabel={false}
                        showValidationSummary={false}>
                        <GroupItem caption='New Banner Bar' cssClass='form-group-item'>
                            <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem />
                            <GroupItem cssClass='form-group-item' colSpan={6}>
                                <EmptyItem />
                                <SimpleItem dataField='subText'>
                                    <Label text='Sub Text' />
                                </SimpleItem>
                                <SimpleItem dataField='navigationLink' editorOptions={navigationLinkEditorOptions}>
                                    <Label text='Navigation Link' />
                                    <PatternRule
                                        message='Navigation link must start with https:// and be at least 16 characters.'
                                        pattern={navigationLinkPattern}
                                    />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='dateStart'
                                    editorType='dxDateBox'
                                    validationRules={validationRules.dateStart}
                                    editorOptions={{ displayFormat: 'EEEE, d of MMM, yyyy HH:mm' }}
                                />
                                <SimpleItem
                                    dataField='dateEnd'
                                    editorType='dxDateBox'
                                    validationRules={validationRules.dateEnd}
                                    editorOptions={{ displayFormat: 'EEEE, d of MMM, yyyy HH:mm' }}
                                />
                                <SimpleItem dataField='orderBy' validationRules={validationRules.sortOrder}>
                                    <Label text='Sort Order' />
                                </SimpleItem>
                                <SimpleItem
                                    dataField='file'
                                    editorType='dxFileUploader'
                                    validationRules={validationRules.file}
                                    editorOptions={{
                                        uploadMode: 'useForm',
                                        multiple: false,
                                        selectButtonText: 'Select file',
                                        labelText: '',
                                        accept: 'image/*',
                                        allowedFileExtensions: ['.jpg', '.jpeg', '.png'],
                                        maxFileSize: 1024 * 512,
                                    }}>
                                    <Label text='Select an Image (750x430px)' />
                                </SimpleItem>
                            </GroupItem>
                        </GroupItem>
                        <EmptyItem />
                        <GroupItem>
                            <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                            <ButtonItem
                                buttonOptions={{
                                    disabled: loading,
                                    icon: loading ? 'refresh' : 'check',
                                    ...submitButtonOptions,
                                }}
                                horizontalAlignment='right'
                            />
                            <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                        </GroupItem>
                    </Form>
                </form>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default AddBannerBarForm
