import React, { useState, useEffect, useCallback } from 'react'
import { Button, List, Typography } from '@mui/material'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import JumboScrollbar from '@jumbo/components/JumboScrollbar'
import LeftInfoPanelItem from './LeftInfoPanelItem'
import Div from '@jumbo/shared/Div'
import { useSelector } from 'react-redux'
import useJumboTheme from '@jumbo/hooks/useJumboTheme'
import LoaderOverlayDashboard from 'app/services/utils/components/LoaderOverlayDashboard'

const LeftInfoPanelList = ({ scrollHeight }) => {
    const { recentEnrolments } = useSelector((state) => state.dashboard)
    const [activeButton, setActiveButton] = useState(recentEnrolments.category?.[0]?.id || '')
    const { loading } = useSelector((state) => state.dashboard)
    const { theme } = useJumboTheme()
    const noDataImgUrl = '/images/no_data.png'

    useEffect(() => {
        if (recentEnrolments.category?.length > 0 && !activeButton) {
            setActiveButton(recentEnrolments.category[0]?.id) // Set to the first category id if activeButton is not set
        }
    }, [recentEnrolments.category, activeButton])

    const handleButtonClick = useCallback(
        (item) => {
            setActiveButton(item.id) // Set active category by id
        },
        [setActiveButton]
    )

    const filteredData =
        activeButton === recentEnrolments.category?.[0]?.id
            ? recentEnrolments.data
            : recentEnrolments.data.filter(
                  (item) =>
                      item.categoryId === activeButton ||
                      item.classTypeName ===
                          recentEnrolments.category.find((cat) => cat.id === activeButton)?.classTypeName
              )

    return (
        <JumboCardQuick
            title={
                <>
                    <Div sx={{ position: 'relative' }}>{loading && <LoaderOverlayDashboard loading={loading} />}</Div>
                    <Div
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            [theme.breakpoints.down('md')]: {
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                            },
                        }}>
                        <Typography
                            variant='h3'
                            mb={0}
                            fontFamily='Lobster, cursive'
                            color='primary.dark'
                            sx={{
                                paddingBottom: '10px',
                                minWidth: 245,
                                [theme.breakpoints.down('md')]: {
                                    minWidth: '100%',
                                    marginBottom: 2,
                                },
                            }}>
                            Recent Enrolments
                        </Typography>

                        <Div sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                            {recentEnrolments.category.map((item) => (
                                <Button
                                    key={item.id}
                                    size='small'
                                    variant={item.id === activeButton ? 'contained' : 'outlined'}
                                    color='primary'
                                    disableElevation
                                    onClick={() => handleButtonClick(item)}
                                    sx={{
                                        textTransform: 'none',
                                        p: (theme) => theme.spacing(0, 1.5),
                                    }}>
                                    {item.classTypeName} {item.className}
                                </Button>
                            ))}
                        </Div>
                    </Div>
                </>
            }
            headerSx={{
                borderBottom: 1,
                borderBottomColor: 'divider',
                '& .MuiCardHeader-action': {
                    my: -0.75,
                },
            }}
            wrapperSx={{
                p: 0,
                minHeight: 400,
                '&:last-child': {
                    pb: 2,
                },
                '& .MuiCollapse-entered:last-child': {
                    '& .MuiListItemButton-root': {
                        borderBottom: 0,
                        borderBottomColor: 'transparent',
                    },
                },
            }}>
            {filteredData.length > 0 ? (
                <JumboScrollbar
                    autoHeight
                    autoHeightMin={scrollHeight || 585}
                    autoHide
                    autoHideDuration={200}
                    autoHideTimeout={500}>
                    <List disablePadding>
                        {filteredData.map((item, index) => (
                            <LeftInfoPanelItem item={item} key={index} />
                        ))}
                    </List>
                </JumboScrollbar>
            ) : (
                !loading && (
                    <Div sx={{ textAlign: 'center', p: 3, m: 'auto' }}>
                        <img alt={'No Data'} src={noDataImgUrl} width='150' style={{ verticalAlign: 'middle' }} />
                        <Typography variant={'h3'} color={'text.secondary'} mt={2}>
                            No Recent Enrolments
                        </Typography>
                    </Div>
                )
            )}
        </JumboCardQuick>
    )
}

export default LeftInfoPanelList
