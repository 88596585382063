import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { selectItemById, updateCustomer, getCustomerById } from 'app/redux/store/features/customers'
import UpdateCustomerForm from './UpdateCustomerForm'
import utilServices from 'app/services/util-services'

const UpdateCustomer = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()
    const { state } = useLocation()
    const { customerId } = state
    const { customers, loading, error } = useSelector((state) => state.customers)
    const customer = selectItemById(customers, customerId)
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const includeBalance = true


    useEffect(() => {
        if (utilServices.isNullOrUndefined(customer.id)) dispatch(getCustomerById({ id: customerId, includeBalance: includeBalance }))
    }, [])

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)

        if (error) handleError(error)
        else {
            enqueueSnackbar('Customer updated successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
            navigate('/registrations/customers')
        }
    }, [loading])

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onUpdateCustomer = async () => {
        setApiCallInProgress(true)
        dispatch(updateCustomer({ ...customer }))
    }

    return (
        <React.Fragment>
            <UpdateCustomerForm customerData={customer} updateCustomer={onUpdateCustomer} />
        </React.Fragment>
    )
}

export default UpdateCustomer
