import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import Divider from '@mui/material/Divider'
import { useSelector, useDispatch } from 'react-redux'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import Form, { ButtonItem, SimpleItem, EmptyItem, GroupItem, ColCountByScreen, Label } from 'devextreme-react/form'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import Typography from '@mui/material/Typography'
import { getUserTypeLookupList } from 'app/redux/store/features/users'
import customerServices from 'app/services/pages/reports/registrations/CustomerServices/customerServices'
import CustomStore from 'devextreme/data/custom_store'
import { useSnackbar } from 'notistack'
import utilServices from 'app/services/util-services'
import { useMediaQuery } from '@mui/material'

const AddUserForm = (props) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const [passwordMatch, setPasswordMatch] = useState(false)
    const [resetPasswordData, setResetPasswordData] = useState({
        password: '',
        verifyPassword: '',
    })
    const [labelLocation, setLabelLocation] = useState('left')
    const sm = useMediaQuery('(max-width:600px)')

    useEffect(() => {
        setLabelLocation(sm ? 'top' : 'left')
    }, [sm])

    const handlePasswordChange = useCallback(
        (e, fieldName) => {
            setResetPasswordData((prevData) => ({
                ...prevData,
                [fieldName]: e.value,
            }))

            if (fieldName === 'password') {
                setPasswordMatch(e.value === resetPasswordData.verifyPassword)
            } else if (fieldName === 'verifyPassword') {
                setPasswordMatch(e.value === resetPasswordData.password)
            }
        },
        [resetPasswordData]
    )

    const { handleSubmit } = useForm({
        resetOptions: {
            keepDirtyValues: true,
            keepErrors: true,
        },
    })
    const { userTypes } = useSelector((state) => state.users)
    const { loading } = useSelector((state) => state.users)

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    useEffect(() => {
        if (userTypes.length <= 0) dispatch(getUserTypeLookupList(''))
    }, [])

    const userTypeLookupStore = new DataSource({
        store: new ArrayStore({
            key: 'id',
            data: userTypes,
            totalCount: userTypes.length,
        }),
        reshapeOnPush: true,
    })

    const userTypeLookupEditorOptions = {
        dataSource: userTypeLookupStore,
        displayExpr: 'typeName',
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
    }

    const submitButtonOptions = {
        text: 'Save',
        type: 'default',
        width: 120,
        useSubmitBehavior: true,
    }

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'Normal',
        width: 120,
        onClick: () => navigate('/security/users', { state: { navigateFrom: 'cancel-add-user' } }),
    }

    const validationRules = {
        firstName: [{ type: 'required', message: 'First Name is required.' }],
        lastName: [{ type: 'required', message: 'Last Name is required.' }],
        email: [
            { type: 'required', message: 'Email is required.' },
            { type: 'email', message: 'Enter a valid email.' },
        ],
        password: [{ type: 'required', message: 'Password is required.' }],

        verifyPassword: [
            { type: 'required', message: 'Verify Password is required.' },
            {
                type: 'custom',
                validationCallback: () => passwordMatch,
                message: 'Password and Verify Password do not match.',
            },
        ],
        userType: [{ type: 'required', message: 'User Type is required.' }],
    }

    const customerLookupList = useCallback(
        async (searchTerm) => {
            try {
                return await customerServices.getCustomerLookupList({ firstName: searchTerm })
            } catch (error) {
                handleError(error)
            }
        },
        [customerServices]
    )

    const customerLookupById = useCallback(
        async (key) => {
            try {
                return await customerServices.getCustomerLookupById(key)
            } catch (error) {
                handleError(error)
            }
        },
        [customerServices]
    )

    const customerLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await customerLookupList(searchTerm)
                    },
                    byKey: async function (key) {
                        if (key === '') return await utilServices.emptyList()
                        return customerLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const customerEditorOptions = {
        dataSource: customerLookupsStore,
        itemTemplate: function (data) {
            return data ? data.firstName.toFullName(data.lastName) : ''
        },
        displayExpr: function (data) {
            return data ? data.firstName.toFullName(data.lastName) : ''
        },
        valueExpr: 'id',
        searchEnabled: true,
        showClearButton: true,
    }

    return (
        <React.Fragment>
            <JumboCardQuick
                title={
                    <Typography color={'common.white'} variant={'h3'} sx={{ mt: 0.8 }}>
                        Add User
                    </Typography>
                }
                headerSx={{
                    height: 40,
                    bgcolor: 'primary.main',
                }}>
                <div>
                    <form onSubmit={handleSubmit(props.addUser)}>
                        <Form
                            mode='form'
                            formData={props.userData}
                            showColonAfterLabel={false}
                            showValidationSummary={false}
                            labelLocation={labelLocation}>
                            <GroupItem cssClass='form-group-item' caption='New User'>
                                <ColCountByScreen lg={13} md={13} sm={1} xs={1} />
                                <GroupItem />
                                <GroupItem />
                                <GroupItem />
                                <GroupItem cssClass='form-group-item' colSpan={6}>
                                    <EmptyItem />
                                    <SimpleItem
                                        dataField='userType'
                                        editorType='dxSelectBox'
                                        editorOptions={userTypeLookupEditorOptions}
                                        validationRules={validationRules.userType}
                                    />
                                    <SimpleItem dataField='firstName' validationRules={validationRules.firstName} />
                                    <SimpleItem dataField='lastName' validationRules={validationRules.lastName} />
                                    <SimpleItem dataField='email' validationRules={validationRules.email} />
                                    <SimpleItem
                                        dataField='password'
                                        editorType='dxTextBox'
                                        validationRules={validationRules.password}
                                        editorOptions={{
                                            mode: 'password',
                                            value: resetPasswordData.password,
                                            onValueChanged: (e) => handlePasswordChange(e, 'password'),
                                            validationMessageMode: 'always',
                                        }}
                                    />
                                    <SimpleItem
                                        dataField='verifyPassword'
                                        editorType='dxTextBox'
                                        validationRules={validationRules.verifyPassword}
                                        editorOptions={{
                                            mode: 'password',
                                            value: resetPasswordData.verifyPassword,
                                            onValueChanged: (e) => handlePasswordChange(e, 'verifyPassword'),
                                            validationMessageMode: 'always',
                                        }}
                                    />
                                    <SimpleItem dataField='comments' editorType='dxTextArea' />
                                    <SimpleItem dataField='birthDate' editorType='dxDateBox' />
                                    <EmptyItem />
                                    <GroupItem>
                                        <Divider />
                                    </GroupItem>
                                    <EmptyItem />
                                    <SimpleItem
                                        dataField='customerId'
                                        editorType='dxSelectBox'
                                        editorOptions={customerEditorOptions}>
                                        <Label text='Linked Customer' />
                                    </SimpleItem>
                                </GroupItem>
                            </GroupItem>
                            <EmptyItem />
                            <GroupItem>
                                <ColCountByScreen lg={2} md={2} sm={2} xs={2} />
                                <ButtonItem
                                    buttonOptions={{
                                        disabled: loading,
                                        icon: loading ? 'refresh' : 'check',
                                        ...submitButtonOptions,
                                    }}
                                    horizontalAlignment='right'
                                />
                                <ButtonItem buttonOptions={cancelButtonOptions} horizontalAlignment='left' />
                            </GroupItem>
                        </Form>
                    </form>
                </div>
            </JumboCardQuick>
        </React.Fragment>
    )
}

export default AddUserForm
