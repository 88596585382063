import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { CustomPaging, PagingState, SortingState, SelectionState } from '@devexpress/dx-react-grid'
import { Paper, useMediaQuery } from '@mui/material'
import {
    Grid,
    Table,
    TableHeaderRow,
    VirtualTable,
    PagingPanel,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import { updateSectionConfig } from 'app/redux/store/features/sections'
import { clearPlacement } from 'app/redux/store/features/placements'
import NoDataCellWithLoading from 'app/services/utils/components/NoDataCellWithLoading'
import LoadingCircle from 'app/services/utils/components/LoadingCircle/LoadingCircle'
import { useJumboTheme } from '@jumbo/hooks'
import { styled } from '@mui/material/styles'

const SectionGridData = ({
    onPageChange,
    onColumnSort,
    setCurrentPage,
    currentPage,
    apiCallInProgress,
    setApiCallInProgress,
}) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const [selectedRowIds, setSelectedRowIds] = useState([])
    const [sorting, setSorting] = useState([{ columnName: 'sectionName', direction: 'asc' }])
    const pageSize = 25
    const { sections, loading, error, refreshing } = useSelector((state) => state.sections)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (!apiCallInProgress || loading) return
        setApiCallInProgress(false)
        if (error) handleError(error)
    }, [loading])

    useEffect(() => {
        if (sections.data.length > 0) {
            dispatch(updateSectionConfig({ sectionId: sections.data[0]?.id }))
            setSelectedRowIds([sections.data[0]?.id])
        }
    }, [sections.data])

    // useEffect(() => {
    //     dispatch(clearPlacement());
    // }, [sections.data]);

    const handleError = (error) => {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        } else {
            enqueueSnackbar(error, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        }
    }

    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }

    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const StyledTableRow = styled(TableSelection.Row)(({ theme }) => ({
        '&.selected': {
            backgroundColor: `${theme.palette.action.selected} !important`,
            '&:hover': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))

    const TableRow = ({ tableRow, ...restProps }) => {
        return (
            <StyledTableRow
                {...restProps}
                onClick={() => {
                    dispatch(updateSectionConfig({ sectionId: tableRow.row.id }))
                    setSelectedRowIds([tableRow.row.id])
                }}
                selected={selectedRowIds.includes(tableRow.row.id)}
                className={selectedRowIds.includes(tableRow.row.id) ? 'selected' : ''}
            />
        )
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={loading} {...props} />

    const onSelectionChange = (selectedRowIds) => {
        setSelectedRowIds(selectedRowIds)
    }

    const renderTeacherCell = (row) => {
        return row.teacherFirstName ? `${row.teacherFirstName} ${row.teacherLastName}` : ''
    }

    const Cell = (props) => {
        let style = { height: '42px' }
        return <VirtualTable.Cell {...props} style={style} />
    }

    const columns = [
        { name: 'sectionName', title: 'Section' },
        { name: 'teacherName', title: 'Teacher', getCellValue: renderTeacherCell },
        { name: 'level', title: 'Level' },
        { name: 'capacity', title: 'Capacity' },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'sectionName', width: min ? '130px' : '28%', wordWrapEnabled: true, minWidth: '130px' },
            { columnName: 'teacherName', width: min ? '130px' : '27%', wordWrapEnabled: true, minWidth: '130px' },
            {
                columnName: 'level',
                width: min ? '100px' : '22%',
                wordWrapEnabled: true,
                minWidth: '100px',
                align: 'center',
            },
            { columnName: 'capacity', width: min ? '120px' : '23%', minWidth: '120px', align: 'center' },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])

    const sortingColumnExtensions = [
        { columnName: 'Action', sortingEnabled: false },
        { columnName: 'teacherName', sortingEnabled: false },
    ]

    return (
        <Paper style={{ position: 'relative' }}>
            <Grid rows={sections.data} columns={columns}>
                {sections.totalCount !== 0 && <LoadingCircle refreshing={refreshing} />}
                <SortingState
                    sorting={sorting}
                    onSortingChange={onSortingChange}
                    columnSortingEnabled={true}
                    columnExtensions={sortingColumnExtensions}
                />
                <PagingState currentPage={currentPage} onCurrentPageChange={onCurrentPageChange} pageSize={pageSize} />
                <CustomPaging totalCount={sections.totalCount} />
                <Table
                    columnExtensions={columnWidths}
                    noDataCellComponent={NoDataCellWithLoadingType}
                    cellComponent={Cell}
                />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths}
                    defaultColumnWidths={columnWidths}
                    resizingMode='nextColumn'
                    columnExtensions={columnWidths}
                />
                <TableColumnVisibility />
                <TableHeaderRow showSortingControls />
                <SelectionState selectedRowIds={selectedRowIds} onSelectionChange={onSelectionChange} />
                <TableSelection selectByRowClick rowComponent={TableRow} showSelectionColumn={false} />
                <PagingPanel />
            </Grid>
        </Paper>
    )
}

export default SectionGridData
