import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { SelectBox } from 'devextreme-react/select-box'
import DataSource from 'devextreme/data/data_source'
import ArrayStore from 'devextreme/data/array_store'
import { useSnackbar } from 'notistack'
import { CustomPaging, DataTypeProvider, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid'
import {
    Grid,
    PagingPanel,
    Table,
    TableHeaderRow,
    TableSelection,
    TableColumnResizing,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'
import SaveIcon from '@mui/icons-material/Save'
import ClearIcon from '@mui/icons-material/Clear'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { Paper, useMediaQuery } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import {
    getPlacementList,
    getPlacements,
    updatePlacement,
    updatePlacementConfig,
} from 'app/redux/store/features/placements'
import { getSectionLookup, getSectionLookupList } from 'app/redux/store/features/sections'
import { formatDateTime } from 'app/services/utils/components/FormatDateTime/FormatDateTime'
import NoDataCellWithLoading from 'app/services/utils/components/NoDataCellWithLoading'
import LoadingCircle from 'app/services/utils/components/LoadingCircle/LoadingCircle'
import { useJumboTheme } from '@jumbo/hooks'

const PendingGrid = () => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { showDialog, hideDialog } = useJumboDialog()
    const [apiCallInProgress, setApiCallInProgress] = useState(false)
    const [placementChanges, setPlacementChanges] = useState({})
    const dateTimeColumns = ['updateTime']
    const [filter, setFilter] = useState({
        sort: 'firstName',
        isDescending: false,
    })
    const [sorting, setSorting] = useState([{ columnName: 'firstName', direction: 'asc' }])
    const [currentPage, setCurrentPage] = useState(0)
    const pageSize = 25
    const {
        placements,
        placementConfig,
        loading: placementLoading,
        error: placementError,
        refreshing,
    } = useSelector((state) => state.placements)
    const { termConfig, loading: termLoading, error: termError } = useSelector((state) => state.terms)
    const { classConfig, loading: classLoading, error: classError } = useSelector((state) => state.classes)
    const { sectionLookups, loading: sectionLoading, error: sectionError } = useSelector((state) => state.sections)
    const { theme } = useJumboTheme()
    const sm = useMediaQuery(theme.breakpoints.down('sm'))
    const mddown = useMediaQuery(theme.breakpoints.down('md'))
    const md = useMediaQuery(theme.breakpoints.between('md', 'xl'))
    const min = sm || mddown || md

    useEffect(() => {
        if (classConfig.classId) {
            onGetPlacement()
            dispatch(getSectionLookupList({ classId: classConfig.classId }))
        } else {
            // Reset the drop down and the list
            dispatch(getPlacements({ data: [], totalCount: 0 }))
            dispatch(getSectionLookup([]))
        }
    }, [classConfig.classId])

    useEffect(() => {
        // When term id changed, we always reset the grid and drop down, however after the class id selection it will be populated.
        dispatch(getPlacements({ data: [], totalCount: 0 }))
        dispatch(getSectionLookup([]))
    }, [termConfig.termId])

    const onGetPlacement = (refresh = false) => {
        if (termConfig.termId) {
            setApiCallInProgress(true)
            dispatch(
                getPlacementList(
                    {
                        ...filter,
                        termId: termConfig.termId,
                        classId: classConfig.classId,
                        sectionId: '',
                        PhoneNumber: '',
                        skip: 0,
                        take: pageSize,
                    },
                    refresh
                )
            )
            setCurrentPage(0)
        }
    }

    useEffect(() => {
        if (!apiCallInProgress || placementLoading || termLoading || classLoading || sectionLoading) return
        setApiCallInProgress(false)
        const error = placementError || termError || classError || sectionError
        if (error) handleError(error)
    }, [placementLoading, termLoading, classLoading, sectionLoading])

    const sectionLookupsStore = new DataSource({
        store: new ArrayStore({
            key: 'id',
            data: sectionLookups,
            totalCount: sectionLookups.length,
        }),
        reshapeOnPush: true,
    })

    useEffect(() => {
        if (placementConfig.reloadAfterUpdate) {
            if (placementError) {
                enqueueSnackbar(placementError.detail, {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
            } else {
                enqueueSnackbar('Section data updated successfully', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                })
                onGetPlacement() // To refresh the Placement list
            }
            dispatch(updatePlacementConfig({ reloadAfterUpdate: false }))
        }
    }, [placementConfig.reloadAfterUpdate])

    const onUpdatePlacement = () => {
        const keysArray = Object.keys(placementChanges)
        const placementData = { data: [] }
        keysArray.map((data, index) => {
            placementData.data.push({
                id: placementChanges[keysArray[index]].placementId,
                termId: placementChanges[keysArray[index]].termId,
                classId: placementChanges[keysArray[index]].classId,
                sectionId: placementChanges[keysArray[index]].sectionId,
            })
        })
        dispatch(updatePlacement(placementData))

        hideDialog()
        setPlacementChanges({})
    }

    const renderPlacementCell = (rowData) => {
        const handlePlacementChange = (value) => {
            setPlacementChanges((prevChanges) => ({
                ...prevChanges,
                [rowData.id]: {
                    ...value,
                    placementId: rowData.id,
                    termId: rowData.termId,
                    classId: rowData.classId,
                    sectionId: value.id,
                },
            }))
        }
        return (
            <div>
                <SelectBox
                    dataSource={sectionLookupsStore}
                    displayExpr={'sectionName'}
                    value={placementChanges[rowData.id] || rowData.sectionName}
                    searchEnabled={true}
                    onValueChange={handlePlacementChange}
                    placeholder='All'
                />
            </div>
        )
    }

    const renderMemberNameCell = (row) => {
        return row.memberFirstName ? row.memberFirstName + ' ' + row.memberLastName : ''
    }

    const renderCustomerNameCell = (row) => {
        return row.firstName ? row.firstName + ' ' + row.lastName : ''
    }

    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const onPageChange = (pageNumber) => {
        const currentFilter = { skip: pageSize * pageNumber, take: pageSize }
        onGetPlacement(currentFilter)
    }

    const onColumnSort = (sortOrder) => {
        const currentFilter = {
            sort: sortOrder[0].columnName,
            isDescending: sortOrder[0].direction !== 'asc',
            take: pageSize,
        }
        onGetPlacement(currentFilter)
        setFilter(currentFilter)
    }
    const onCurrentPageChange = (pageNumber) => {
        onPageChange(pageNumber)
        setCurrentPage(pageNumber)
    }
    const onSortingChange = (sortOrder) => {
        onColumnSort(sortOrder)
        setSorting(sortOrder)
    }

    const NoDataCellWithLoadingType = (props) => <NoDataCellWithLoading loading={placementLoading} {...props} />
    const DateTimeTypeProvider = (props) => <DataTypeProvider formatterComponent={formatDateTime} {...props} />

    const handleSaveChanges = () => {
        showDialog({
            variant: 'confirm',
            title: 'This will update the selected enrolments, do you want to continue?',
            onYes: () => {
                onUpdatePlacement()
            },
            onNo: hideDialog,
        })
    }

    const columns = [
        { name: 'firstName', title: 'Customer Name', getCellValue: renderCustomerNameCell },
        { name: 'memberFirstName', title: 'Member Name', getCellValue: renderMemberNameCell },
        { name: 'familyName', title: 'Family Name' },
        { name: 'updateTime', title: 'Registration' },
        { name: 'sectionName', title: 'Placement', getCellValue: renderPlacementCell },
    ]

    const columnWidth = useMemo(
        () => [
            { columnName: 'firstName', width: min ? '150px' : '21%', wordWrapEnabled: true, minWidth: '150px' },
            { columnName: 'memberFirstName', width: min ? '150px' : '21%', wordWrapEnabled: true, minWidth: '150px' },
            { columnName: 'familyName', width: min ? '150px' : '21%', wordWrapEnabled: true, minWidth: '150px' },
            { columnName: 'updateTime', width: min ? '150px' : '17%', wordWrapEnabled: true, minWidth: '150px' },
            { columnName: 'sectionName', width: min ? '150px' : '20%', wordWrapEnabled: true, minWidth: '150px' },
        ],
        [min]
    )

    const [columnWidths, setColumnWidths] = useState(columnWidth)

    useEffect(() => {
        setColumnWidths(columnWidth)
    }, [min])


    const sortingColumnExtensions = [
        { columnName: 'memberFirstName', sortingEnabled: false }, // that we will enable it in future
    ]

    return (
        <JumboCardQuick
            noWrapper
            title={
                <Typography color={'common.white'} variant={'h3'}>
                    <AutorenewRoundedIcon
                        fontSize={'large'}
                        onClick={() => onGetPlacement({}, true)}
                        sx={{
                            mb: -1.3,
                            mr: 1,
                            color: 'common.white',
                            '&:hover': {
                                cursor: 'pointer',
                            },
                        }}
                    />
                    Enrolments
                </Typography>
            }
            action={
                <Stack direction={'row'} spacing={1} sx={{ mt: -3 }}>
                    <Tooltip title='Save Changes'>
                        <IconButton
                            disabled={Object.keys(placementChanges).length === 0}
                            aria-label='save'
                            sx={{ color: 'common.white' }}
                            onClick={handleSaveChanges}>
                            <SaveIcon fontSize={'large'} onClick={handleSaveChanges} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Discard Changes'>
                        <IconButton
                            disabled={Object.keys(placementChanges).length === 0}
                            sx={{ color: 'common.white' }}
                            onClick={() => setPlacementChanges({})}>
                            <ClearIcon fontSize={'large'} />
                        </IconButton>
                    </Tooltip>
                </Stack>
            }
            headerSx={{
                height: 40,
                bgcolor: 'success.main',
                pl: 1,
            }}>
            <Paper style={{ position: 'relative' }}>
                <Grid rows={placements.data} columns={columns}>
                    {placements.totalCount !== 0 && <LoadingCircle refreshing={refreshing} />}
                    <DateTimeTypeProvider for={dateTimeColumns} />
                    <SortingState
                        sorting={sorting}
                        onSortingChange={onSortingChange}
                        columnSortingEnabled={true}
                        columnExtensions={sortingColumnExtensions}
                    />
                    <PagingState
                        currentPage={currentPage}
                        onCurrentPageChange={onCurrentPageChange}
                        pageSize={pageSize}
                    />
                    <CustomPaging totalCount={placements.totalCount} />
                    <Table columnExtensions={columnWidths} noDataCellComponent={NoDataCellWithLoadingType} />
                    <TableColumnResizing
                        columnWidths={columnWidths}
                        onColumnWidthsChange={setColumnWidths}
                        defaultColumnWidths={columnWidths}
                        resizingMode='nextColumn'
                        columnExtensions={columnWidths}
                    />
                    <TableColumnVisibility />
                    <TableHeaderRow showSortingControls />
                    <SelectionState />
                    <TableSelection selectByRowClick showSelectionColumn={false} />
                    <PagingPanel />
                </Grid>
            </Paper>
        </JumboCardQuick>
    )
}

export default PendingGrid
