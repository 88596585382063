import { createSelector, createSlice } from '@reduxjs/toolkit'
import { apiCallBegan } from '../actions/api'

const initialState = {
    users: { data: [], totalCount: 0 },
    userLookups: [],
    userTypes: [],
    userTypeConfig: { id: null, readOnly: false },
    loading: false,
    refreshing: false,
    error: null,
}

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        refreshRequested: (state, action) => {
            state.loading = true
            state.refreshing = true
            state.error = null
        },
        apiRequested: (state, action) => {
            state.loading = true
            state.error = null
        },
        apiRequestedFailed: (state, action) => {
            state.error = action.payload
            state.loading = false
            state.refreshing = false
        },
        getUserTypeConfig: (state, action) => {
            state.userTypeConfig = action.payload
        },
        updateUserTypeConfig: (state, action) => {
            state.userTypeConfig = action.payload
        },
        getUsers: (state, action) => {
            state.users = action.payload
            state.loading = false
            state.refreshing = false
        },
        getUserLookup: (state, action) => {
            state.userLookups = action.payload
            state.loading = false
        },
        getExistingUserById: (state, action) => {
            const index = state.users.data.findIndex((user) => user.id === action.payload.id)
            if (index >= 0) state.users[index] = action.payload
            else {
                state.users.data.push(action.payload)
                state.users.totalCount++
            }
            state.loading = false
        },
        addNewUser: (state, action) => {
            state.users.data.push(action.payload)
            state.users.totalCount++
            state.loading = false
        },
        updateExistingUser: (state, action) => {
            const index = state.users.data.findIndex((user) => user.id === action.payload.id)
            if (index >= 0) state.users.data.splice(index, 1, action.payload)
            state.loading = false
        },
        removeUser: (state, action) => {
            const index = state.users.data.findIndex((user) => user.id === action.payload.id)
            if (index >= 0) {
                state.users.data.splice(index, 1)
                state.users.totalCount--
            }
            state.loading = false
        },
        getUserTypeLookup: (state, action) => {
            state.userTypes = action.payload
            state.loading = false
        },
        getExistingUserTypeLookupById: (state, action) => {
            const index = state.userTypes.findIndex((userType) => userType.id === action.payload.id)
            if (index >= 0) state.userTypes[index] = action.payload
            else {
                state.userTypes.push(action.payload)
            }
            state.loading = false
        },
    },
})

const selectItems = (state) => state.data
const selectItemId = (state, itemId) => itemId
const selectItemById = createSelector([selectItems, selectItemId], (items, itemId) => {
    if (items) {
        const index = items.findIndex((item) => item.id === itemId)
        if (index >= 0) return { ...items[index] }
    }
    return {}
})
export { selectItemById }

export const {
    apiRequested,
    refreshRequested,
    apiRequestedFailed,
    getUserTypeConfig,
    updateUserTypeConfig,
    getUsers,
    getUserLookup,
    getExistingUserById,
    getUserTypeLookup,
    getExistingUserTypeLookupById,
    addNewUser,
    updateExistingUser,
    removeUser,
} = userSlice.actions
export default userSlice.reducer

export const getUserList = (filter, refresh = false) => {
    let url = `/api/User/list`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: filter,
        onStart: refresh ? refreshRequested.type : apiRequested.type,
        onSuccess: getUsers.type,
        onError: apiRequestedFailed.type,
    })
}

export const getUserLookupList = (filter) => {
    let url = `/User/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getUserLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getUserById = (filter) => {
    let url = `/api/User/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingUserById.type,
        onError: apiRequestedFailed.type,
    })
}

export const getUserTypeLookupList = (filter) => {
    let url = filter ? `/api/User/type/lookup/list?search=${filter}` : `/api/User/type/lookup/list`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getUserTypeLookup.type,
        onError: apiRequestedFailed.type,
    })
}

export const getUserTypeLookupById = (filter) => {
    let url = `/api/User/type/lookup/${filter.id}`
    return apiCallBegan({
        url: url,
        method: 'get',
        data: filter,
        onStart: apiRequested.type,
        onSuccess: getExistingUserTypeLookupById.type,
        onError: apiRequestedFailed.type,
    })
}

export const addUser = (user) => {
    let url = `/api/User/add`
    return apiCallBegan({
        url: url,
        method: 'post',
        data: user,
        onStart: apiRequested.type,
        onSuccess: addNewUser.type,
        onError: apiRequestedFailed.type,
    })
}

export const updateUser = (user) => {
    let url = `/api/User/update`
    return apiCallBegan({
        url: url,
        method: 'PUT',
        data: user,
        onStart: apiRequested.type,
        onSuccess: updateExistingUser.type,
        onError: apiRequestedFailed.type,
    })
}

export const deleteUser = (user) => {
    let url = '/api/User/delete'
    return apiCallBegan({
        url: url,
        method: 'DELETE',
        data: user,
        onStart: apiRequested.type,
        onSuccess: removeUser.type,
        onError: apiRequestedFailed.type,
    })
}
