import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SelectBox } from 'devextreme-react/select-box'
import { useSnackbar } from 'notistack'
import Div from '@jumbo/shared/Div'
import JumboCardQuick from '@jumbo/components/JumboCardQuick'
import SearchIcon from '@mui/icons-material/Search'
import utilServices from 'app/services/util-services'
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import Typography from '@mui/material/Typography'
import termServices from 'app/services/pages/courses/setup/termServices/termServices'
import { Divider, Stack } from '@mui/material'
import { Form, Formik } from 'formik'
import LoadingButton from '@mui/lab/LoadingButton'
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded'

const TermGridFilter = ({ mutation, display }) => {
    const navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [termSelection, setTermSelection] = useState('')
    let initialValues = {}
    function handleError(error) {
        if (error.status === '401') {
            navigate('/profile/signout')
        } else if (error.detail) {
            enqueueSnackbar(error.detail, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        } else {
            enqueueSnackbar(error, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    }

    const termYearNumber = useCallback((data) => {
        return data ? data.termYear + ' / ' + data.termNumber : ''
    }, [])

    const termLookupList = useCallback(async (searchTerm) => {
        try {
            return await termServices.getTermLookupList({ termYear: searchTerm })
        } catch (error) {
            handleError(error)
        }
    }, [])

    const termLookupById = useCallback(async (key) => {
        try {
            return await termServices.getTermLookupById(key)
        } catch (error) {
            handleError(error)
        }
    }, [])

    const termLookupsStore = useMemo(
        () =>
            new DataSource({
                store: new CustomStore({
                    key: 'id',
                    load: async function (loadOptions) {
                        let searchTerm = ''
                        if (loadOptions.searchValue) {
                            searchTerm = loadOptions.searchValue
                        }
                        return await termLookupList(searchTerm)
                    },
                    byKey: function (key) {
                        if (key === '') return utilServices.emptyList()

                        return termLookupById(key)
                    },
                    errorHandler: function (e) {
                        console.log(e)
                        return utilServices.emptyList()
                    },
                }),
                reshapeOnPush: true,
            }),
        []
    )

    const onFormSubmit = async () => {
        await mutation.mutate({
            termYear: termSelection ? termSelection.termYear : null,
            termNumber: termSelection ? termSelection.termNumber : null,
            sort: 'termYear',
            isDescending: false,
        })
    }

    const onFilterSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true)
        await onFormSubmit()
        setSubmitting(false)
    }

    const onClearFilter = () => {
        setTermSelection(null)
    }

    return (
        <Formik
            validateOnChange={true}
            initialValues={initialValues}
            onSubmit={onFilterSubmit}
            onReset={onFilterSubmit}>
            {({ isSubmitting }) => (
                <Form noValidate autoComplete='off'>
                    <JumboCardQuick noWrapper sx={{ display: display, borderRadius: 0, paddingLeft: '12px' }}>
                        <Divider />
                        <Div
                            className='grid-filter-parent'
                            style={{
                                display: 'flex',
                                margin: '0% 1.5% 4% 1.5%',
                                flexWrap: 'wrap',
                            }}>
                            <Div sx={{ mr: 3, mt: 1.5 }}>
                                <Div>
                                    <Typography>Term</Typography>
                                </Div>
                                <Div sx={{ mt: 0.5 }}>
                                    <SelectBox
                                        style={{ width: 160 }}
                                        dataSource={termLookupsStore}
                                        itemTemplate={termYearNumber}
                                        displayExpr={termYearNumber}
                                        value={termSelection}
                                        searchEnabled={true}
                                        onValueChange={setTermSelection}
                                        placeholder='All'
                                        showClearButton={true}
                                    />
                                </Div>
                            </Div>
                            <Div className='grid-filter-Button' sx={{ mt: 4.5 }}>
                                <Stack direction={'row'} spacing={1}>
                                    <Div>
                                        <LoadingButton
                                            size={'small'}
                                            type='submit'
                                            variant={'contained'}
                                            loading={isSubmitting || mutation.isLoading}>
                                            <SearchIcon />
                                        </LoadingButton>
                                    </Div>
                                    <Div>
                                        <LoadingButton
                                            size={'small'}
                                            type={'reset'}
                                            onClick={onClearFilter}
                                            variant={'contained'}>
                                            <AutorenewRoundedIcon />
                                        </LoadingButton>
                                    </Div>
                                </Stack>
                            </Div>
                        </Div>
                    </JumboCardQuick>
                </Form>
            )}
        </Formik>
    )
}

export default TermGridFilter
